import {
    getCookie,
    setCookie,
    eraseCookie
} from "./helper"
import API from "../api/user"
import {
    initHeaders
} from '../api/events'

export const isBrowser = () => typeof window !== 'undefined'

export const getUserToken = () => {
    return isBrowser() && getCookie('login_id') ? getCookie('login_id') : ''
}

export const setUserToken = (token) => {
    setCookie('login_id', token)
}

export const handleLogin = async ({
    hhNo,
    mobileNo,
    otp
}, login) => {
    const json = {
        hhNo,
        mobileNo,
        otp,
    }
    try {
        const response = await API.loginUser(json)
        if (response.token) {
            if(login) {
                setUserToken(response.token)
                setCookie('hs', response.hh_number)
                setCookie('user', JSON.stringify({...response, pin: response?.hh_number, mobileno: mobileNo})) // to be removed later when global state is implemented
            }
            initHeaders()
            return {
                ['apiResponse']: true,
                ['data']: response
            };
        } else {
            return {
                ['apiResponse']: false,
                ['data']: 'Invalid OTP'
            };
        }
    } catch (error) {
        return {
            ['apiResponse']: false,
            ['data']: error?.response?.data
        };
    }
}

export const handleHHLogin = async (id) => {
    try {
        const response = await API.getUserFromHH(id)
        if(response[0]?.pinNo) {
            // setUserToken(JSON.stringify(response[0]))
            // setCookie('hs', response[0]?.pinNo)
            // setCookie('user', JSON.stringify(response[0]))
            initHeaders()
            return response[0];
        } else {
            return false;
        }
    } catch (error) {
        console.log(error)
        return false;
    }
}

export const handleRegister = async (payload) => {
    try {
        const response = await API.registerUser(payload)
        if (response.token) {
            setUserToken(response.token)
            setCookie('hs', response.hh_number)
            initHeaders()
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error)
        return false;
    }
}

export const isLoggedIn = () => {
    const token = getCookie('login_id')
    return !!token
}
export const logout = (callback) => {
    eraseCookie('login_id')
    callback()
}